import React from "react";

import classes from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";

const NavigationItems = props => (
  <React.Fragment>
    <ul className={classes.NavigationItems}>
      <NavigationItem to="header" clicked={props.clicked}>Start</NavigationItem>
      <NavigationItem to="info" clicked={props.clicked}>Info</NavigationItem>
      <NavigationItem to="gallery" clicked={props.clicked}>Galeria</NavigationItem>
      <NavigationItem to="offer" clicked={props.clicked}>Oferty</NavigationItem>
      <NavigationItem to="contact" clicked={props.clicked}>Kontakt</NavigationItem>
    </ul>
  </React.Fragment>
);

export default NavigationItems;
