import React from "react";

import classes from "./Info.module.css";

const Contact = props => {
  return (
    <section id="info">
      <div className={classes.Info}>
        <div className={classes.Text}>
          <p><b className={classes.Highlighted5}></b>Planujesz imprezę typu wesele, osiemnastka lub jakąkolwiek inną<b className={classes.Highlighted4}>?</b></p>
          <p><b className={classes.Highlighted5}></b>Chcesz aby goście się świetnie bawili<b className={classes.Highlighted4}>?</b></p>
          <p><b className={classes.Highlighted5}></b>Chciałbyś mieć pamiątkę po niej na całe życie<b className={classes.Highlighted4}>?</b></p>
          <b className={classes.Highlighted}>Bardzo dobrze trafiłeś!</b>
          <p>Naszą fotobudke możesz wynająć na <b className={classes.Highlighted2}>każdą imprezę!</b></p>
          <p>
            Gwarantujemy <b className={classes.Highlighted3}>super zabawę</b> przy robieniu zdjęć w różnych śmiesznych
            przebraniach!
        </p>
          <p>
            Zdjęcia są <b className={classes.Highlighted2}>wspaniałą pamiątką</b> zarówno dla organizatorów jak i dla gości!
        </p>
        <p>
            Fotobudka wyzwala w gościach <b className={classes.Highlighted3}>dziecięcą radość</b>, podkręcając tym samym
            imprezę oraz pomaga w integracji!
        </p>
        <p>
          Zapewniamy obsługę na <b className={classes.Highlighted2}>najwyższym poziomie!</b>
        </p>
        <p>
            <a className={classes.Btn} href="#contact">SKONTAKTUJ SIĘ Z NAMI</a> a przedstawimy Ci ofertę oraz odpowiemy na
              wszystkie pytania!
        </p>
        </div>
        <div className={classes.Img}>&nbsp;</div>

      </div>
    </section>
  );
};

export default Contact;
