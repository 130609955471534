import React, { useState, useEffect } from "react";

import classes from "./Gallery.module.css";

const SectionGallery = props => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const importAll = (r) => {
      let imgs = [];
      imgs = r.keys().map(item => { return { src: r(item) } });
      return imgs;
    }
    const imagesArray = importAll(require.context('../../../assets/images/gallery', false, /\.(png|jpe?g|svg)$/));
    setImages(imagesArray);
  }, []);

  return (
    <section className={classes.Gallery}>
      <div className={classes.GalleryContainer} id="gallery">
        {images.map(img => {
          return <div className={classes.ImageContainer}><img className={classes.Image} src={img.src} alt="" /></div>
        })}
      </div>
    </section>
  );
};

export default SectionGallery;
