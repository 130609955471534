import React from "react";

import classes from "./Footer.module.css";

const Footer = props => {
  const currentDate = new Date().getFullYear();

  return (
    <footer className={classes.Footer}>
      <span><a className={classes.FooterLink} href="/">&copy; <b>{currentDate}</b> Fotobudka Premium Box</a></span>
      <span><a className={classes.FooterLink} href="https://front-up.pro/">Website created by <b>Front Up</b></a></span>
    </footer>
  );
};

export default Footer;
