import React from "react";

import classes from "./Card.module.css";

const Card = props => {
  let bonus = null;
  if (props.bonus) {
    bonus = (
      <li>
        <b>{props.bonus}</b>
      </li>
    );
  }

  const classesWithType = [];
  switch (props.type) {
    case "b":
      classesWithType.push(classes.Bronze);
      break;
    case "s":
      classesWithType.push(classes.Silver);
      break;
    case "g":
      classesWithType.push(classes.Gold);
      break;
    default:
      break;
  }

  return (
    <li className={classes.CardItem}>
      <div className={classes.Card}>
        <h2 className={classesWithType.join(" ")}>Oferta {props.title}</h2>
        <div className={classes.Content}>
          <p className={classes.Time}>
            Czas trwania: <b>{props.time}</b>
          </p>
          <ul>
            <li>Wynajem automatu</li>
            <li>Osoba do obsługi na cały czas wynajmu</li>
            <li>Nielimitowana liczba zdjęć</li>
            <li>Wybor formatu zdjęcia</li>
            <li>Wybor szablonu zdjęć</li>
            <li>Możliwość nałożenia dowolnego napisu lub logotypu na zdjęciach</li>
            <li>Green Screen</li>
            <li>Pudło z gadżetami</li>
            <li>Stopień dla dzieci</li>
            <li>Pendrive ze zdjęciami</li>
            <li>Galeria online</li>
            <li>Zdjęcia kolorowe, czarno-białe, sepia i inne</li>
            <li>Tło stałe winylowe</li>
            <li>Możliwość podpisu zdjęcia palcem</li>
          </ul>
          <p>
            Księga gości <b>{props.book}</b>
          </p>
          <p className={classes.Price}>
            Cena: <b>{props.price}</b>
          </p>
          {bonus}
        </div>
      </div>
    </li>
  );
};

export default Card;
