import React from "react";
import { Link } from "react-scroll";

import classes from "./NavigationItem.module.css";

const NavigationItem = props => (
  <li className={classes.NavigationItem}>
    <Link
      to={props.to}
      spy={true}
      smooth={true}
      hashSpy={true}
      duration={500}
      isDynamic={true}
      offset={-15}
      ignoreCancelEvents={false}
      onClick={props.clicked}
    >
      {props.children}
    </Link>
  </li>
);

export default NavigationItem;
