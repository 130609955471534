import React from "react";

import classes from "./Cards.module.css";
import Card from "./Card/Card";

const Cards = props => {
  return (
    <ul className={classes.Cards}  id="offer">
      <Card title="brązowa" time="2 godziny" book="(za dopłatą: 50zł)" price="499 zł" type="b" />
      <Card title="srebrna" time="3 godziny" book="(darmowa!)" price="699 zł" type="s" />
      <Card title="złota" time="4 godziny" book="(darmowa!)" price="849 zł" type="g" bonus="+ Każda dodatkowa godzina 150 zł"/>
    </ul>
  );
};

export default Cards;
