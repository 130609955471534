import React from "react";

import classes from "./Header.module.css";

const Header = props => {
  return (
    <header className={classes.Header} id="header">
      <div className={classes.TextBox}>
        <h1 className={classes.Title}>Fotobudka Premium&nbsp;Box</h1>
        <p className={classes.Paragraph}>
          Wynajem fotobudki na różnego typu imprezy. Dzięki niej każda zabawa
          stanie się jeszcze lepsza!
        </p>
      </div>
    </header>
  );
};

export default Header;
