import React from "react";

import classes from "./Offer.module.css";
import Cards from "./Cards/Cards";

const Offer = props => {
  return (
    <section className={classes.Offer}>
      <div className={classes.Background}>OFERTY</div>
      <Cards />
    </section>
  );
};

export default Offer;
