import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import classes from './Contact.module.css';

const Contact = (props) => {
	const facebookLink = 'https://www.facebook.com/Premium-Box-Fotobudka-118907812842942/';
	const instagramLink = 'https://www.instagram.com/fotobudkapremiumbox';
	return (
		<section className={classes.Contact}>
			<h2>Dane kontaktowe</h2>
			<div className={classes.Content} id="contact">
				<div className={classes.Item}>
					<h3>Kontakt</h3>
					<a className={classes.Mail} href="mailto:kontakt@fotobudka-premiumbox.pl">
						kontakt@fotobudka-premiumbox.pl
					</a>
					<p>518 879 641</p>
				</div>
				<div className={classes.Item}>
					<h3>Adres</h3>
					<p>Przybynów ul. Wojska Polskiego 22</p>
					<p>42-310 Żarki</p>
				</div>
				<div className={classes.ItemLink}>
					<a href={facebookLink} className={classes.Icon}>
						<FontAwesomeIcon icon={faFacebook} />
					</a>
					<p>
						<a className={classes.Link} href={facebookLink}>
							Odwiedź nas na fb!
						</a>
					</p>
				</div>
				<div className={classes.ItemLink}>
					<a href={instagramLink} className={classes.Icon}>
						<FontAwesomeIcon icon={faInstagram} />
					</a>
					<p>
						<a className={classes.Link} href={instagramLink}>
							Zapraszamy na ig!
						</a>
					</p>
				</div>
			</div>
		</section>
	);
};

export default Contact;
