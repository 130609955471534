import React from "react";

import "./App.css";
import Layout from "./containers/Layout/Layout";
import Content from "./components/Content/Content";

function App() {
  return (
    <div className="App">
      <Layout>
        <Content />
      </Layout>
    </div>
  );
}

export default App;
