import React from "react";

import Header from "./Header/Header";
import Gallery from "./Gallery/Gallery";
import Offer from "./Offer/Offer";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import Info from "./Info/Info";

import classes from "./Content.module.css";

const Content = () => {
  return (
    <div className={classes.Content}>
      <Header />
      <main>
        <Info />
        <Gallery />
        <Offer />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default Content;
